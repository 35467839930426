@tailwind base;
@tailwind components;
@tailwind utilities;

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

html {
  @apply !scroll-smooth bg-black;
}

body {
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  padding-top: var(--cigar-bar-height, '0px');
}

a,
input,
button {
  @apply focus-visible:outline-none;
}

a,
button,
[role='button'] {
  -webkit-tap-highlight-color: transparent;
}

@layer utilities {
  .tiny-scrollbar {
    &::-webkit-scrollbar {
      @apply w-1;
    }

    &::-webkit-scrollbar-track {
      @apply rounded-full bg-white;
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded-full bg-acqua-500;
    }
  }

  .search {
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }

  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .range-track {
    @apply h-0.5 w-full rounded-sm bg-gray-400;
  }

  .range-thumb {
    @apply relative z-20 h-3.5 w-3.5 appearance-none rounded-full bg-white;
  }
}

input[type='range'] {
  --val: calc((var(--range-value, 50) - 0) * 1%);
  @apply relative w-full cursor-pointer appearance-none rounded-sm border-none bg-gray-400 outline-none focus-visible:z-10 focus-visible:outline-2 focus-visible:outline-acqua-500;
  @apply before:absolute before:left-0 before:top-0 before:z-0 before:h-0.5 before:w-[var(--range-value)] before:rounded-sm before:bg-white before:content-[""];
  @apply after:absolute after:-top-1.5 after:left-0 after:z-10 after:h-3.5 after:w-full;
}

input[type='range']::-webkit-slider-runnable-track {
  @apply range-track;
}

input[type='range']::-webkit-slider-thumb {
  @apply range-thumb -mt-1.5;
}

input[type='range']::-moz-range-track {
  @apply range-track;
}

input[type='range']::-moz-range-thumb {
  @apply range-thumb;
}

input[type='range']::-ms-track {
  @apply range-track;
}

input[type='range']::-ms-thumb {
  @apply range-thumb;
}

input[type='range']::-ms-fill-lower {
  @apply rounded-sm bg-white;
}

input[type='range']::-ms-fill-upper {
  @apply rounded-sm bg-gray-400;
}

input[type='range']::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    #ffffff 0%,
    #ffffff var(--val),
    #2d2d2d var(--val),
    #2d2d2d 100%
  );
}

input[type='range']::-moz-range-progress {
  @apply bg-white;
}

input[type='range']::-ms-fill-lower {
  @apply bg-white;
}
